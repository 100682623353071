import { default as _91_46_46_46all_93hPBVBoTqrrMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue?macro=true";
import { default as addresshHnRQjFJWPMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue?macro=true";
import { default as indexQu7bHXNyi9Meta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue?macro=true";
import { default as order8xbI84GYUqMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue?macro=true";
import { default as paymentPGaNyU2jHoMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue?macro=true";
import { default as profilePd4kt4J2SAMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue?macro=true";
import { default as recover8ENK7MDzDSMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue?macro=true";
import { default as indexM18bDV2h9SMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue?macro=true";
import { default as indexV9ubT6jwHXMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue?macro=true";
import { default as errorWezjSmBPsZMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue?macro=true";
import { default as loginhPMTVGFPQHMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue?macro=true";
import { default as maintenanceKQyo11uMspMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue?macro=true";
import { default as newsletter_45subscribetdAmUCYdwPMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue?macro=true";
import { default as registerHKobSP6e19Meta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue?macro=true";
import { default as reset_45password1HkWOm2phYMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue?macro=true";
import { default as search2E7YUNedvR8Meta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue?macro=true";
import { default as wishlistWnq1pe7TIoMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___de",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/de/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___en",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/en/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___de",
    path: addresshHnRQjFJWPMeta?.path ?? "/de/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___en",
    path: addresshHnRQjFJWPMeta?.path ?? "/en/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___de",
    path: indexQu7bHXNyi9Meta?.path ?? "/de/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___en",
    path: indexQu7bHXNyi9Meta?.path ?? "/en/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___de",
    path: order8xbI84GYUqMeta?.path ?? "/de/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___en",
    path: order8xbI84GYUqMeta?.path ?? "/en/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___de",
    path: paymentPGaNyU2jHoMeta?.path ?? "/de/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___en",
    path: paymentPGaNyU2jHoMeta?.path ?? "/en/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___de",
    path: profilePd4kt4J2SAMeta?.path ?? "/de/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___en",
    path: profilePd4kt4J2SAMeta?.path ?? "/en/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___de",
    path: recover8ENK7MDzDSMeta?.path ?? "/de/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___en",
    path: recover8ENK7MDzDSMeta?.path ?? "/en/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___de",
    path: indexM18bDV2h9SMeta?.path ?? "/de/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___en",
    path: indexM18bDV2h9SMeta?.path ?? "/en/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___de",
    path: indexV9ubT6jwHXMeta?.path ?? "/de/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___en",
    path: indexV9ubT6jwHXMeta?.path ?? "/en/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___de",
    path: errorWezjSmBPsZMeta?.path ?? "/de/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___en",
    path: errorWezjSmBPsZMeta?.path ?? "/en/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___de",
    path: loginhPMTVGFPQHMeta?.path ?? "/de/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___en",
    path: loginhPMTVGFPQHMeta?.path ?? "/en/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___de",
    path: maintenanceKQyo11uMspMeta?.path ?? "/de/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___en",
    path: maintenanceKQyo11uMspMeta?.path ?? "/en/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___de",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/de/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___en",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/en/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___de",
    path: registerHKobSP6e19Meta?.path ?? "/de/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___en",
    path: registerHKobSP6e19Meta?.path ?? "/en/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___de",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/de/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___en",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/en/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___de",
    path: search2E7YUNedvR8Meta?.path ?? "/de/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___en",
    path: search2E7YUNedvR8Meta?.path ?? "/en/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___de",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/de/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___en",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/en/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  }
]